@import "../../../base/fn";

.weui-form-preview{
    position: relative;
    background-color: #FFFFFF;
    &:before{
        .setTopLine(@weuiCellBorderColor);
    }
    &:after{
        .setBottomLine(@weuiCellBorderColor);
    }
}
.weui-form-preview__hd{
    position: relative;
    padding: @weuiCellGapV @weuiCellGapH;
    text-align: right;
    line-height: 2.5em;
    &:after{
        .setBottomLine(@weuiCellBorderColor);
        left: @weuiCellGapH;
    }
    .weui-form-preview__value{
        font-style: normal;
        font-size: 1.6em;
    }
}
.weui-form-preview__bd{
    padding: @weuiCellGapV @weuiCellGapH;
    font-size: .9em;
    text-align: right;
    color: @weuiTextColorGray;
    line-height: 2;
}
.weui-form-preview__ft{
    position: relative;
    line-height: 50px;
    display: flex;
    &:after {
        .setTopLine(@weuiDialogLineColor);
    }
}
.weui-form-preview__item{
    overflow: hidden;
}
.weui-form-preview__label{
    float: left;
    margin-right: 1em;
    min-width: 4em;
    color: @weuiTextColorGray;
    text-align: justify;
    text-align-last: justify;
}
.weui-form-preview__value{
    display: block;
    overflow: hidden;
    word-break:normal;
    word-wrap: break-word;
}
.weui-form-preview__btn {
    position: relative;
    display: block;
    flex: 1;
    color: @weuiDialogLinkColor;
    text-align: center;
    .setTapColor();
    button&{
        background-color: transparent;
        border: 0;
        outline: 0;
        font-size: inherit;
    }
    &:active {
        background-color: @weuiDialogLinkActiveBc;
    }
    &:after {
        .setLeftLine(@weuiDialogLineColor);
    }
    &:first-child {
        &:after {
            display: none;
        }
    }
}
.weui-form-preview__btn_default {
    color: @weuiTextColorGray;
}
.weui-form-preview__btn_primary {
    color: #0BB20C;
}