@import "../../../base/fn";

.weui-check__label {
    .setTapColor();
}

.weui-check{
    position: absolute;
    left: -9999em;
}
